// @flow
import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { Box } from '@latitude/box';
import { DigitalWallets as RetailPartners } from '@latitude/digital-wallets';
import { Hero } from '@latitude/hero';
import { Text } from '@latitude/text';
import { Promo } from '@latitude/promo';
import { ImportantInformation } from '@latitude/important-information';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import heroImage from './images/hero-spend-get.png';
import heroBackgroundImage from './images/hero-bg-spend-get.png';
import heroBackgroundMobileImage from './images/hero-bg-spend-get-mobile.png';
import { PageContext } from '@/context/PageContext';
import PromoBanner from '@/components/lab-components/PromoBanner';

const partners = [
  {
    title: 'Once It',
    href: 'https://www.onceit.co.nz/?utm_source=Gem+Visa&utm_medium=banner&utm_campaign=gemvisaxonceit',
    trackId: 'once-it',
    imageSrc: require('./images/logos/once-it.webp'),
    width: 100
  },
  {
    title: 'Kathmandu',
    href: 'https://www.kathmandu.co.nz/?utm_source=latitude',
    trackId: 'kathmandu',
    imageSrc: require('./images/logos/kathmandu.webp'),
    width: 100
  },
  {
    title: 'NZ Sale',
    href: 'https://www.nzsale.co.nz/?sc=99&c=99&ca=Genoapay_GemVisa',
    trackId: 'nz-sale',
    imageSrc: require('./images/logos/nz-sale.webp'),
    width: 90
  },
  {
    title: 'Hallenstein Brothers',
    href: 'https://www.hallensteins.com/',
    trackId: 'hallenstein-brothers',
    imageSrc: require('./images/logos/hallenstein-brothers.webp'),
    width: 90
  },
  {
    title: 'Max',
    href: 'https://www.max.co.nz/new-arrivals',
    trackId: 'max-co',
    imageSrc: require('./images/logos/max.webp'),
    width: 80
  },
  {
    title: 'Barkers',
    href: 'https://www.barkersonline.co.nz/?utm_source=latitudeawareness&utm_medium=sponsored-link-post&utm_content=image&utm_campaign=latitude-landingpage',
    trackId: 'barkers',
    imageSrc: require('./images/logos/barkers.webp'),
    width: 90
  }
];

const SpendAndGetPage = () => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer gem-home-page">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/credit-cards/gem-visa-card/campaign/spend-and-get/"
          />
          <title>Gem Finance | Spend and Get</title>
          <meta
            name="description"
            content="Buy what you love and get credit back."
            noIndex
          />
        </Helmet>

        <Box
          css={`
            background: url(${heroBackgroundMobileImage})
                no-repeat center center;
                background-size: cover;

            @media (min-width: ${BREAKPOINT.MD}) {
                background: url(${heroBackgroundImage})
                no-repeat center center;
                background-size: cover;
            }

            [class^='Herostyles__HeroContent'] {
                background: transparent;
                padding: 0 0 52px;

                @media (min-width: ${BREAKPOINT.MD}) {
                padding: 80px 0;
                }

                @media (min-width: ${BREAKPOINT.LG}) {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 552px; /* As per UX team request */
                }

                @media (min-width: ${BREAKPOINT.XL}) {
                min-width: 564px;
                }
            }

            [class^='Herostyles__HeroImage'] {
                display: flex;
                height: auto;
                justify-content: center;
                top: 10px;
            }
          `}
        >
          <Hero
            title={['Buy what you love and get credit back.']}
            text="Please check your email inbox to see if you qualify to enjoy even more from your Gem Visa credit card. Offer ends 27 April 2022."
            imageContent={
              <img
                src={heroImage}
                alt="Prizes"
                css={`
                height: 300px;
                right: -5px;
                top: 16px;

            @media (min-width: ${BREAKPOINT.MD}) {
                width: 450px
                height: auto;
                }

            @media (min-width: ${BREAKPOINT.LG}) {
                width: 530px;
                height: auto;
                right: 0px;
                top: 0px;
                  }
                `}
              />
            }
          />
        </Box>
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
        <Box.Section
          css={`
            justify-content: center;
            display: flex;

            // h3 {
            //   color: ${COLOR.BLUE_DEEP};
            // }
          `}
        >
          <Promo
            title="Buy what you love and get credit back."
            description={
              <Text>
                Want another reason to whip out Gem Visa from your wallet?
                <br />
                <br />
                From fab fashion to your favourite food delivery, every swipe and tap brings you closer to credit back. Please check your email inbox to see if you qualify.
              </Text>
            }
            frameImage={
              <img
                src={require('./images/spend-get-promo.png')}
                alt="Gift Cards"
              />
            }
            frameAlignment="left"
            frameBorderColor="#FFA9D5"
          />
        </Box.Section>)
        }

        <RetailPartners
          heading="Use Gem Visa at thousands of retailers in-store and online"
          variant="partners"
          data={partners}
        />

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                  `This offer is strictly limited to the recipient of our Spend &amp; Get email only. Open to New Zealand residents 18 and over. You have been specifically selected for this offer and it is not transferable. Eligible transactions include all Visa purchases and do not include instore and online Sales Finance interest free deals, cash advances, cash equivalent transactions, credit fees or charges, credits, refunds, reimbursements.<br / ><br / >Offer is valid from 30 March to 11.59pm 27 April 2022. To qualify you must fulfil all the terms of the offer by activating the offer and spending the amount specified in the Spend &amp; Get email you received from Gem, using your Gem Visa during the offer period. Your account credit will appear on your statement within 60 days of the offer end date. The account must be open and not in default of the credit contract when the credit is applied to your account. You can only qualify once for this offer.<br / ><br / >Credit and lending criteria and fees apply including a $52 annual account fee. Prevailing interest rate (currently 25.99% p.a.) applies to any remaining balance on the expiry of the interest free term. Paying only the minimum monthly repayment of 3% of the outstanding monthly balance or $20 (whichever is more), will not be sufficient to repay the purchase amount(s) within the promotional period. Amount payable will be shown on your monthly statement. Credit provided by Latitude Financial Services Limited.`
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1"
        />
      </main>
    </Layout>
  );
};

export default SpendAndGetPage;
